import { SearchParams } from '@/global-types'
import VueRouter from 'vue-router'

export type UpdateRouterOptions = {
  replace: boolean,
}

export function updateRouter(router: VueRouter, options: UpdateRouterOptions = { replace: false }) {
  return (searchParams: SearchParams): void => {
    const info = {
      name: 'AuthorsSearch',
      query: {
        query: searchParams.query,
        page: searchParams.pageNumber.toString(),
        size: searchParams.pageSize.toString(),
        sort: searchParams.sortBy,
        facets: searchParams.facets,
      },
    }

    if (options.replace) {
      router.replace(info)
    } else {
      router.push(info)
    }
  }
}

// export function updateSearchParams(route: Route, searchParams: SearchParams): SearchParams {
//   const query = route.query.query
//     ? (route.query.query as string).trim()
//     : searchParams.query

//   const pageNumber = route.query.page && (route.query.page as string).trim() !== '0'
//     ? parseInt(route.query.page as string)
//     : searchParams.pageNumber

//   const pageSize = route.query.size && (route.query.size as string).trim() !== '0'
//     ? parseInt(route.query.size as string)
//     : searchParams.pageSize

//   const sortBy = route.query.sort
//     ? route.query.sort as string
//     : searchParams.sortBy

//   const facets = route.query.facets
//     ? route.query.facets as string
//     : ''

//   return {
//     query,
//     pageNumber,
//     pageSize,
//     sortBy,
//     facets,
//   }
// }


import { Vue, Component, Watch } from 'vue-property-decorator'
import { SearchBar, SearchToken } from '@/components/search-bar'
import { NavigationTabs, SearchTabs } from '@/components/navigation-tabs'
import { SearchParams } from '@/global-types'
import { AuthorsResults } from '@/components/search-results/authors-results'
import { updateRouter } from '@/components/search-results/authors-results/utils'
import { updateSearchParams } from '@/utils/utils'
import { AuthorNameSuggestions } from '@/components/author-name-suggestions'

@Component({
  components: {
    NavigationTabs,
    SearchBar,
    AuthorsResults,
    AuthorNameSuggestions,
  },
})
export default class AuthorsSearch extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  searchText = ''
  searchParams: SearchParams = {
    query: '',
    pageNumber: 1,
    pageSize: 20,
    sortBy: 'relevance',
    facets: '',
  }

  readonly authorsTab = SearchTabs.authors
  readonly searchFields: SearchToken[] = [
    { name: 'Author Name', value: 'au', format: 'author' },
    { name: 'Variation Name', value: 'vn' },
    { name: 'MR Author ID', value: 'auid', description: 'An ID for a specific author, commonly used in URLs' },
  ]

  readonly searchOperators: SearchToken[] = [
    { name: 'logical AND', value: 'AND', example: 'a AND b' },
    { name: 'logical OR', value: 'OR', example: 'a OR b' },
    { name: 'logical NOT', value: 'NOT', example: 'NOT a' },
  ]

  created() {
    const localPageSize = localStorage.getItem(this.$route.name + 'PageSize')
    if (localPageSize) {
      this.searchParams.pageSize = parseInt(localPageSize)
    }
    this.updateDataFromURLParams()
  }

  async mounted() {
    document.title = 'Author Search - MathSciNet'
  }

  @Watch('$route', { immediate: true })
  onRouteChanged() {
    document.title = 'Author Search - MathSciNet'
    this.updateDataFromURLParams()
  }

  //
  // COMPUTED PROPERTIES
  //
  get internalAccess() {
    return this.$store.state.AppConfigModule.appConfig.internal
  }

  // get searchText() {
  //   return this.$store.state.SearchTextModule.authorsSearchText
  // }

  // set searchText(value) {
  //   this.$store.commit(UPDATE_AUTHORS_SEARCH_TEXT, value)
  // }

  updateDataFromURLParams() {
    this.searchParams = updateSearchParams(this.$route, this.searchParams)
    if (this.searchParams.query) {
      this.searchText = this.searchParams.query
    }
  }

  async search(searchText: string) {
    if (searchText.trim().length > 0) {
      this.$nextTick(() => {
        updateRouter(this.$router)({ ...this.searchParams, query: '' })
        updateRouter(this.$router)({ ...this.searchParams, query: searchText, pageNumber: 1 })
      })
    }
  }

  clear() {
    this.$set(this.searchParams, 'query', '')
    this.$nextTick(() => {
      updateRouter(this.$router, { replace: true })({ ...this.searchParams, query: '' })
    })
  }
}

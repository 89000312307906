
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { Author } from './types'
import { Results } from '@/global-types'
import { updateMathJax } from '@/utils/utils'
import { SearchPagination } from '@/components/search-results'

@Component({
  components: {
    SearchPagination,
  },
})
export default class ResultsComponent extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ required: true }) authors!: Results<Author>
  @Prop({ default: false, type: Boolean }) nestedComponentFormat!: boolean
  @Prop({ default: false, type: Boolean }) filtersOpened!: boolean

  @PropSync('currentPage', { type: Number }) syncedCurrentPage!: number
  @PropSync('pageSize', { type: Number }) syncedPageSize!: number
  @PropSync('sortKey', { type: String }) syncedSortKey!: string

  //
  // WATCHERS
  //
  // @Watch('syncedPageSize', { immediate: true })
  // updatePageSize(newSize: number) {
  //   this.syncedPageSize = newSize
  //   if (newSize !== this.parentPageSize) {
  //     this.parentPageSize = newSize
  //   }
  // }

  // @Watch('syncedCurrentPage', { immediate: true })
  // updateCurrentPage(newPage: number) {
  //   this.syncedCurrentPage = newPage
  //   if (newPage !== this.parentCurrentPage) {
  //     this.parentCurrentPage = newPage
  //   }
  // }

  // @Watch('parentPageSize', { immediate: true })
  // updateSyncedPageSize() {
  //   this.syncedPageSize = this.parentPageSize
  // }

  // @Watch('parentCurrentPage', { immediate: true })
  // updateSyncedCurrentPage() {
  //   this.syncedCurrentPage = this.parentCurrentPage
  // }

  // @Watch('localPageSize', { immediate: true })
  // updateSyncedPageSize(newLocalPageSize) {
  //   if (newLocalPageSize !== this.syncedPageSize) {
  //     localStorage.setItem(this.$route.name + 'PageSize', newLocalPageSize)
  //     this.syncedPageSize = newLocalPageSize
  //   }
  // }

  // @Watch('syncedPageSize', { immediate: true })
  // updateLocalPageSize(newSyncPageSize) {
  //   if (newSyncPageSize !== this.localPageSize) {
  //     // Don't update the localStorage here. The user didn't select this new page size, it came from something like a URL (or being updated by the localPageSize)
  //     this.localPageSize = newSyncPageSize
  //   }
  // }

  //
  // REACTIVE PROPERTIES
  //
  batchOpened = false
  openedPublishedAs: { [key: number]: boolean } = {}
  sortedKeyText = {
    name: 'Name',
    publications: 'Publications',
    citations: 'Citations',
    earliestPublication: 'Earliest Publication',
    relevance: 'Relevance',
  }

  //
  // COMPUTED PROPERTIES
  //

  get totalPubsClassIfNested() {
    return this.nestedComponentFormat ? 'd-block d-md-none' : ''
  }

  get authorsResult() {
    return this.authors.results.map(author => {
      const variations = !author.variations ? [] : author.variations.map(item => {
        const variationExtraInfo = author.variationsExtraInfo.find(v => v.name.trim() === item.name.trim())
        let citations = 0
        let earliestPublication = { year: 0, paperId: 0 }

        if (variationExtraInfo) {
          citations = variationExtraInfo.citations
          earliestPublication = variationExtraInfo.earliestPublication
        }

        return { ...item, citations, earliestPublication }
      })

      return { ...author, variations }
    })
  }

  get totalPaginationRows() {
    return this.authors.total > 1000 ? 1000 : this.authors.total
  }

  //
  // HOOKS
  //

  updated() {
    updateMathJax()
  }

  mounted() {
    // this.localPageSize = this.syncedPageSize
    // if (this.parentCurrentPage) {
    //   this.syncedCurrentPage = this.parentCurrentPage
    // }
    // if (this.parentPageSize) {
    //   this.syncedPageSize = this.parentPageSize
    // }
    updateMathJax()
  }

  //
  // METHODS
  //

  // updateCurrentPage(currentPage: number) {
  //   this.syncedCurrentPage = currentPage
  // }

  updatePageSize(pageSize: number) {
    this.syncedPageSize = pageSize
  }

  updateCurrentPage(pageNumber: number) {
    this.syncedCurrentPage = pageNumber
  }

  getMoreResults() {
    this.$emit('more')
  }

  toggleFilters() {
    this.$emit('toggle-filters')
  }

  toggleFiltersModal() {
    this.$emit('toggle-filters-modal')
  }

  sortBy(sortKey: string) {
    this.syncedSortKey = sortKey
  }

  togglePublishedAs(authorId: number) {
    Vue.set(this.openedPublishedAs, authorId, this.openedPublishedAs[authorId] ? !this.openedPublishedAs[authorId] : true)
  }
}
